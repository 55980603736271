import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import Login from './Login';
import Bulat from './Bulat';
import Board from './game/Board';
import BoardHeaded from './game_headed/Board';
import BoardGrid from './game_grid/Board';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import { Provider } from 'react-redux'
import {store} from './app/store'
import { StrictMode } from 'react';

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/bulat",
    element: <Bulat />,
  },
  {
    path: "/game",
    element: <Board />,
  },
  {
    path: "/game_headed",
    element: <BoardHeaded />,
  },
  {
    path: "/rectangular",
    element: <BoardGrid />,
  },
]);

const rootElement = document.getElementById('root');
const root = ReactDOM.createRoot(rootElement!);

root.render(
  // <StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  // </StrictMode>
);
