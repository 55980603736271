import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { Stage, Layer, Rect, Text } from 'react-konva';
import Box from '@mui/material/Box';
import LinearProgress from '@mui/material/LinearProgress';
import useInterval from './UseInterval';
import { calculateTargetLen, getRandom, isHit } from './Helpers';
import { Block, Models, Position, PressedKeys } from './Types';
import { Button, Drawer, FormControlLabel, FormLabel, Radio, RadioGroup, Typography, useMediaQuery, useTheme } from '@mui/material';
import DrawerList from './DrawerList';
import Konva from 'konva';
import usePlayers from './hooks/usePlayers';
import { ActionsStateList } from './components/ActionsStateList';
import ExportSuccessDialog from './ExportSuccessDialog';
import { Tutorial } from './components/Tutorial';
import Tooltip from '@mui/material/Tooltip';
import useScore from './hooks/useScore';
import { Score } from './components/Score';
import useAccountRoles from './hooks/useAccountRoles';


const Canvas = (props: any) => {
    const slowMoTimes = 50;
    const scaleRate = 6;
    const timeScale = 1;
    const blockWidthRate = 5;
    const playerTimeIntervalSkip = 5;
    const blockTimeIntervalSkip = playerTimeIntervalSkip * 15;
    const eyeIndicatorStartValue = 10;
    const eyeExtraWidth = 4;

    const mainIntervalDefault = 5;

    const slowMotionRation = 5;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const toggleSpeedButtonHeight = 85;

    // make up for defualt margin of 8px (all the sides).
    const width = window.innerWidth - 16;
    const height = window.innerHeight - 16 - (fullScreen ? toggleSpeedButtonHeight : 0);
    const blockSnapSizeDefault = Math.round(width / 55);

    const [blockSnapSize, setBlockSnapSize] = useState<number>(blockSnapSizeDefault);
    const [blockWidth, setBlockWidth] = useState<number>(blockSnapSize * blockWidthRate);
    const [temperature, setTemperature] = useState<number>(0.8);
    const [targetLen, setTargetLen] = useState<number>(7);

    const [isSlow, setIsSlow] = useState(false);
    const [mainTimeInterval, setMainTimeInterval] = useState<number>(mainIntervalDefault);
    const [isGoToBase, setIsGoToBase] = useState(false);
    const [slowMoProgress, setSlowMoProgress] = useState(0);

    const [currentKeys, setCurrentKeys] = useState<PressedKeys>(null);

    const [isOnPause, setIsOnPause] = useState(false);
    const [isOnItself, setIsOnItself] = useState(true);
    const [open, setOpen] = useState(false);
    const [mousePosition, setMousePosition] = useState<Position>()

    const [isScaled, setIsScaled] = useState(false);
    const [isOpenActionsState, setIsOpenActionsState] = useState(false);

    const [selectedModel, setSelectedModel] = useState<Models>("transformers");

    const [showExportDialog, setShowExportDialog] = useState(false);
    const [showTutorial, setShowTutorial] = useState(false);

    const [block, setBlock] = useState<Block>({
        id: 0,
        position: [
            Math.round(getRandom(0, width - blockWidth) / blockSnapSize) * blockSnapSize,
            0],
        currentTimeSkipInterval: 0,
        isEyeDirectionDown: true,
        eyeIndicator: eyeIndicatorStartValue,
    });
    const [
        players,
        checkMovement,
        setClearHistory,
        addMouseClickPosition,
        initPlayersPosition,
        printPlayers,
        exportPlayers,
        onDeleteActionState,
        saveActionState,
        exportBadActions,
    ] = usePlayers({
        count: 1,
        block: block,
        timerInterval: mainTimeInterval,
        pressedKeys: currentKeys,
        blockSnapSize: blockSnapSize,
        areaWidth: width,
        areaHeight: height,
        isOnPause: isOnPause,
        isSlow: isSlow,
        isGoToBase: isGoToBase,
        isOnItself: isOnItself,
        onBaseReached: useCallback(() => {
            setIsGoToBase(false);
        }, [setIsGoToBase]),
        onExportSuccess: useCallback(() => {
            setShowExportDialog(true);
        }, [setShowExportDialog]),
        temperature: temperature,
        targetLen: targetLen,
        model: selectedModel,
    });

    const [hitsCount, missesCount, hit, miss] = useScore();
    const [isFixing, setIsFixing] = useState(false);

    const [roles] = useAccountRoles();

    useEffect(() => {
        setBlockWidth(blockSnapSize * blockWidthRate);
        setBlock((block) => {
            block.position = [
                Math.round(block.position[0] / blockSnapSize) * blockSnapSize,
                Math.round(block.position[1] / blockSnapSize) * blockSnapSize];
            return block;
        })
    }, [blockSnapSize]);

    const slowDown = useCallback(() => {
        if (isSlow) {
            console.debug("Already in slow motion.");
            return;
        }
        setIsSlow(true);
        setMainTimeInterval(i => i * slowMotionRation * timeScale);
    }, [isSlow, setIsSlow, setMainTimeInterval]);

    const speedUp = useCallback(() => {
        if (!isSlow) {
            return;
        }
        setIsSlow(false);
        setSlowMoProgress(0);
        setMainTimeInterval(i => (i / slowMotionRation) * timeScale);
    }, [isSlow, setIsSlow, setSlowMoProgress, setMainTimeInterval]);

    const toggleSpeed = useCallback(() => {
        if (isSlow) {
            speedUp();
        } else {
            slowDown();
        }
    }, [isSlow, speedUp, slowDown]);


    const toggleDrawer = useCallback((newOpen: boolean): void => {
        setOpen(newOpen);
    }, [setOpen]);

    const onKeyDown = useCallback((event: any) => {
        const wasHorizontalKeyPressed = ["ArrowLeft", "ArrowRight"].some((key) => event.key === key);
        if (wasHorizontalKeyPressed) {
            event.preventDefault();
            setIsGoToBase(false);
            if (currentKeys && currentKeys[0]) {
                return;
            }
            setCurrentKeys((k) => {
                if (!k) {
                    k = [null, null]
                }
                k[0] = event.key;
                return k;
            });
            const isLeft = event.key == "ArrowLeft";

            // setPlayerPosition(([x, y]) => [isLeft ? x - blockSnapSize : x + blockSnapSize, y]);
        }
        const wasVerticalKeyPressed = ["ArrowUp", "ArrowDown"].some((key) => event.key === key);
        if (wasVerticalKeyPressed) {
            event.preventDefault();
            setIsGoToBase(false);
            if (currentKeys && currentKeys[1]) {
                return;
            }
            setCurrentKeys((k) => {
                if (!k) {
                    k = [null, null]
                }
                k[1] = event.key;
                return k;
            });
            const isUp = event.key == "ArrowUp";

            // setPlayerPosition(([x, y]) => [x, isUp ? y - blockSnapSize : y + blockSnapSize]);
        }
        const wasSpaceKeyPressed = [" "].some((key) => event.key === key);
        if (wasSpaceKeyPressed) {
            event.preventDefault();
            toggleSpeed();
        }
        const wasEnterKeyPressed = ["Enter"].some((key) => event.key === key);
        if (wasEnterKeyPressed) {
            event.preventDefault();
            setIsGoToBase(true);
        }
        const wasEscapeKeyPressed = ["Escape"].some((key) => event.key === key);
        if (wasEscapeKeyPressed) {
            event.preventDefault();
            onPause(null);
        }
        const wasPKeyPressed = ["p", "P"].some((key) => event.key === key);
        if (wasPKeyPressed) {
            event.preventDefault();
            printPlayers();
        }
        const wasEscape2Pressed = ["`"].some((key) => event.key === key);
        if (wasEscape2Pressed) {
            event.preventDefault();
            toggleDrawer(false);
        }
        const wasLPressed = ["l", "L"].some((key) => event.key === key);
        if (wasLPressed) {
            event.preventDefault();
            setIsOpenActionsState(true);
        }
    }, [setIsOpenActionsState, toggleDrawer, printPlayers, setIsGoToBase, toggleSpeed]);

    const onMouseDown = useCallback((evt: Konva.KonvaEventObject<MouseEvent>) => {
        if (!evt.target) {
            return;
        }
        const stage = evt.target.getStage();
        if (!stage) {
            return;
        }
        const pos = stage.getPointerPosition();
        if (!pos) {
            return;
        }

        const x = Math.round((pos.x - (blockSnapSize / 2)) / blockSnapSize) * blockSnapSize;
        const y = Math.round((pos.y - (blockSnapSize / 2)) / blockSnapSize) * blockSnapSize;
        addMouseClickPosition([x, y]);
    }, [addMouseClickPosition, blockSnapSize])

    useEffect(() => {
        document.addEventListener('keydown', onKeyDown);
        return () => {
            document.removeEventListener('keydown', onKeyDown);
        };
    }, [onKeyDown]);

    const mainTimer = useInterval(() => {
        for (const player of players) {
            if (block) {
                if (block.currentTimeSkipInterval >= blockTimeIntervalSkip) {
                    block.currentTimeSkipInterval = 0;
                    if (block.position[1] > height) {
                        setClearHistory(true);
                        block.position = [(Math.round(getRandom(0, width - blockWidth) / blockSnapSize) * blockSnapSize), 0];
                        miss();
                    } else {
                        const newPosition: Position = [block.position[0], block.position[1] + blockSnapSize];
                        // checkMovement(newPosition);
                        block.position = newPosition;
                        setTargetLen(calculateTargetLen(player.blockDistance, blockSnapSize));
                    }
                    if (block.eyeIndicator <= 0) {
                        block.isEyeDirectionDown = !block.isEyeDirectionDown;
                        block.eyeIndicator = eyeIndicatorStartValue;
                    } else {
                        block.eyeIndicator -= 1;
                    }
                } else {
                    block.currentTimeSkipInterval += 1
                }
            }
            if (player.currentTimeSkipInterval >= playerTimeIntervalSkip) {
                player.currentTimeSkipInterval = 0
                checkMovement();
                if (isSlow) {
                    const slowMoProgressSnapSize = Math.round(100 / slowMoTimes);
                    let newSlowMoProgress = slowMoProgress + slowMoProgressSnapSize;
                    if (newSlowMoProgress >= 100) {
                        speedUp();
                    } else {
                        setSlowMoProgress(newSlowMoProgress);
                    }
                }
            } else {
                player.currentTimeSkipInterval += 1;
            }
            if (isHit(player, block, blockWidth, blockSnapSize, isOnItself)) {
                if (isSlow) {
                    saveActionState();
                    if (isFixing) {
                        fixMissClick(player.fixedMissId);
                        setIsFixing(false);
                    }
                }
                setClearHistory(true);
                speedUp();
                block.position[0] = Math.round(getRandom(0, width - blockWidth) / blockSnapSize) * blockSnapSize;
                block.position[1] = 0;
                hit();
            } else {
                const eyeExtraWidthSize = blockSnapSize * eyeExtraWidth;
                if (player.position[0] >= (block.position[0] - eyeExtraWidthSize)
                    && player.position[0] < (block.position[0] + blockWidth + eyeExtraWidthSize)) {
                    if (block.isEyeDirectionDown) {
                        if (player.position[1] >= (block.position[1] + blockSnapSize)
                            && player.position[1] <= (block.position[1] + (blockSnapSize * 2))) {
                            miss();
                            initPlayersPosition(blockSnapSize);
                            setClearHistory(true);
                            speedUp();
                            if (isOnItself) {
                                if (roles?.includes("Admin")) {
                                    exportBadActions(player.id);
                                }
                            }
                        }
                    } else {
                        if (player.position[1] < (block.position[1])
                            && player.position[1] >= (block.position[1] - (blockSnapSize * 2))) {
                            miss();
                            initPlayersPosition(blockSnapSize);
                            setClearHistory(true);
                            speedUp();
                            if (isOnItself) {
                                if (roles?.includes("Admin")) {
                                    exportBadActions(player.id);
                                }
                            }
                        }
                    }
                }
            }
        }
    }, mainTimeInterval, isOnPause, []);

    const onPause = useCallback((e: any) => {
        if (!isOnPause) {
            setIsOnPause(true);
            window.clearInterval(mainTimer.current);
            setOpen(true);
        } else {
            setIsOnPause(false);
        }
    }, [isOnPause, setIsOnPause, setOpen]);

    const onItself = useCallback((e: any) => {
        setIsOnItself((isOnItself) => !isOnItself);
    }, [setIsOnItself]);

    const onExport = useCallback((): void => {
        exportPlayers();
    }, [exportPlayers])

    const onMouseMove = useCallback((evt: Konva.KonvaEventObject<MouseEvent>): void => {
        if (!evt.target) {
            return;
        }
        const stage = evt.target.getStage();
        if (!stage) {
            return;
        }
        const pos = stage.getPointerPosition();
        if (!pos) {
            return;
        }
        setMousePosition([Math.round(pos.x), Math.round(pos.y)])
    }, [setMousePosition])

    const onScale = useCallback(() => {
        let newBlockSnapSize = blockSnapSizeDefault;
        if (!isScaled) {
            newBlockSnapSize = Math.round(blockSnapSize / scaleRate);
        }
        setBlockSnapSize(newBlockSnapSize);
        setIsScaled(!isScaled);
        initPlayersPosition(newBlockSnapSize);
    }, [isScaled, setBlockSnapSize, setIsScaled, initPlayersPosition])

    // const onSliderChange = (event: Event, newValue: number | number[]) => {
    //     setTemperature(newValue as number);
    // };

    // const onTargetLenSliderChange = (event: Event, newValue: number | number[]) => {
    //     setTargetLen(newValue as number);
    // };

    const trainOnMissesClick = useCallback(() => {
        setIsOnPause(true);
        if (!players || players.length == 0) {
            console.log("No players");
            return;
        }
        const player = players[0];

        const url = new URL(`${process.env.REACT_APP_PYTHON_BACKEND}/getBadAction`);

        const token = localStorage.getItem("jwt");
        if (!token) {
            throw new Error("Not authorized");
        }

        fetch(url, {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(response);
            })
            .then((data) => {
                if (!data) {
                    alert("There is no data yet. Let the model play itself and if there are some misses occur then the data comes.");
                    return;
                }
                setClearHistory(true);
                setIsSlow(true);
                setIsFixing(true);
                player.blockHistory = data.data.blockState;
                block.position = [
                    Math.round((width / 2 - blockWidth) / blockSnapSize) * blockSnapSize,
                    blockSnapSize * Math.round(height / 2 / blockSnapSize)
                ];

                const lastPosition = data.data.blockState.slice(-1)[0];

                block.eyeIndicator = lastPosition.eyeIndicator
                block.isEyeDirectionDown = lastPosition.isEyeDown;

                player.position[0] = block.position[0] + (lastPosition.position[0] * blockSnapSize);
                player.position[1] = block.position[1] + (lastPosition.position[1] * blockSnapSize);
                player.fixedMissId = data.id;
            })
            .catch((reason: any) => {
                if ("status" in reason) {
                    if (reason.status === 401) {
                        window.location.href = "/";
                    }
                } else {
                    alert("An error occured during exporting. Please, try later.");
                }
            });
    }, [setIsOnPause, players, block, blockWidth, blockSnapSize, width, height]);

    const fixMissClick = useCallback((id: number) => {
        if (!players || players.length == 0) {
            console.log("No players");
            return;
        }

        const url = new URL(`${process.env.REACT_APP_PYTHON_BACKEND}/fixBadActions`);

        const token = localStorage.getItem("jwt");
        if (!token) {
            throw new Error("Not authorized");
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + token,
            },
            body: JSON.stringify({
                id: id,
            })
        })
            .then((response) => {
                if (!response.ok) {
                    return Promise.reject(response);
                }
            })
            .catch((reason: any) => {
                if ("status" in reason) {
                    if (reason.status === 401) {
                        window.location.href = "/";
                    }
                } else {
                    alert("An error occured during exporting. Please, try later.");
                }
            });
    }, [setIsOnPause, players]);

    const handleModelChange = (e: React.ChangeEvent<HTMLInputElement>) =>
        setSelectedModel(e.target.value as Models);

    return (
        <div tabIndex={1} style={{ backgroundColor: isSlow ? "#e6ffff" : undefined }}>
            <Box sx={{ width: width }} position={"absolute"} marginTop={"-6px"} display={isSlow ? undefined : "None"} >
                <LinearProgress variant="determinate" value={slowMoProgress} color="info" />
            </Box>
            <Box position={"absolute"} zIndex={1000} width={"100%"}>
                <Button variant={!isOnPause ? "outlined" : "contained"} onClick={onPause} color="success">{isOnPause ? "Resume" : "Pause"}</Button>
                <Tooltip title="The trained model takes over the Red and makes it move" arrow>
                    <Button
                        variant={!isOnItself ? "outlined" : "contained"}
                        onClick={onItself} color="success"
                        sx={{ backgroundColor: isOnItself ? null : '#fceae8' }}>
                        {isOnItself ? "Switch To Train Mode" : "Let it play on its own"}
                    </Button>
                </Tooltip>
                <Button variant={!isScaled ? "outlined" : "contained"} onClick={onScale} color="success">{"Zoom " + (isScaled ? "in" : "out")}</Button>
                <Button variant="outlined" sx={{ backgroundColor: '#e6f7ff', marginRight: "10px" }} color='info' onClick={useCallback(() => { setShowTutorial(true) }, [setShowTutorial])} ><b>About</b></Button>
                {roles?.includes("Admin") && <Button variant="outlined" sx={{ backgroundColor: '#e6f7ff', marginRight: "10px" }} color='info' onClick={trainOnMissesClick} ><b>Train on misses</b></Button>}
                <Score hits={hitsCount} misses={missesCount} />
                <Box>
                    <FormLabel id="selecte_model_lbl">The model</FormLabel>
                    <RadioGroup
                        aria-labelledby="model-buttons-group"
                        name="model-buttons-group"
                        value={selectedModel}
                        onChange={handleModelChange}
                    >
                        <FormControlLabel value="transformers" control={<Radio />} label="transformers" />
                        <FormControlLabel value="lstm" control={<Radio />} label="lstm" />
                    </RadioGroup>
                </Box>
                {/* <Typography fontFamily="monospace" fontSize="large" variant="body1" color="steelblue">
                    Model's Confidence Score Threshold: {temperature}
                </Typography>
                <Slider style={{ width: "200px" }}
                    value={temperature}
                    onChange={onSliderChange}
                    aria-labelledby="input-slider"
                    step={0.1}
                    marks
                    min={0.1}
                    max={1}
                    valueLabelDisplay="auto"
                />
                <Typography fontFamily="monospace" fontSize="large" variant="body1" color="steelblue">
                    Sequence length predicted by model: {targetLen}
                </Typography>
                <Slider style={{ width: "200px" }}
                    value={targetLen}
                    onChange={onTargetLenSliderChange}
                    aria-labelledby="input-slider"
                    step={1}
                    marks
                    min={1}
                    max={40}
                    valueLabelDisplay="auto"
                /> */}
            </Box>
            <Box position={"absolute"} zIndex={1000} width={"50px"}>
            </Box>
            <Drawer open={open} onClose={() => toggleDrawer(true)}>
                <DrawerList
                    onToggleDrawer={toggleDrawer}
                    onResume={() => { setOpen(false); onPause(false); }}
                    onExport={() => onExport()}
                />
            </Drawer>
            <Stage
                key="main_stage"
                width={width}
                height={height}
                onMouseDown={onMouseDown}
                onMouseMove={onMouseMove}>
                <Layer>
                    {/* <Lines padding={blockSnapSize} width={width} height={height} /> */}
                    {players.map((p) => {
                        return <Fragment key={p.id}>
                            <Rect
                                key={"player_" + p.id}
                                x={p.position[0]}
                                y={p.position[1]}
                                width={blockSnapSize}
                                height={blockSnapSize}
                                fill='#F00101'
                                stroke='#ddd'
                                strokeWidth={1}
                                shadowColor='black'
                                shadowBlur={2}
                                shadowOffset={{ x: 1, y: 1 }}
                                shadowOpacity={0.4}
                            />
                            {/* <Text
                                key={"player" + p.id + "_text"}
                                text={p.position[0]?.toString() + " : " + p.position[1]?.toString()}
                                x={p.position[0]}
                                y={p.position[1] + 20}
                                fontSize={30}
                                fontFamily={'Calibri'}
                                fill={'green'}
                            />
                            <Text
                                key={"player" + p.id + "_text2"}
                                text={p.id.toString()}
                                x={p.position[0]}
                                y={p.position[1]}
                                fontSize={30}
                                fontFamily={'Calibri'}
                                fill={'blue'}
                            /> */}
                            {
                                p.mouseClickPositions.map((mcp, idx) => <Rect
                                    key={"mouseClick_" + idx}
                                    x={mcp[0]}
                                    y={mcp[1]}
                                    width={blockSnapSize}
                                    height={blockSnapSize}
                                    fill='#ffe6e6'
                                    stroke='#ddd'
                                    strokeWidth={1}
                                    shadowColor='black'
                                    shadowBlur={2}
                                    shadowOffset={{ x: 1, y: 1 }}
                                    shadowOpacity={0.4}
                                />)
                            }
                        </Fragment>
                    })}
                    <Rect
                        key="block"
                        x={block.position[0]}
                        y={block.position[1]}
                        width={blockWidth}
                        height={blockSnapSize}
                        fill='#349B51'
                        opacity={0.6}
                        stroke='#ddd'
                        strokeWidth={1}
                        shadowColor='black'
                        shadowBlur={2}
                        shadowOffset={{ x: 1, y: 1 }}
                        shadowOpacity={0.6}
                    />
                    {isOnItself && <Rect
                        key="block_increased_hit_distance"
                        x={block.position[0] - blockSnapSize * 2}
                        y={block.position[1] - blockSnapSize}
                        width={blockWidth + blockSnapSize * 4}
                        height={blockSnapSize + blockSnapSize * 2}
                        fill='#FFC0CB'
                        opacity={0.2}
                    />}
                    <Rect
                        key="eye"
                        x={block.position[0] - blockSnapSize * eyeExtraWidth}
                        y={block.position[1] + blockSnapSize * (block.isEyeDirectionDown ? 1 : -2)}
                        width={blockWidth + (eyeExtraWidth * blockSnapSize) * 2}
                        height={blockSnapSize + blockSnapSize}
                        fill='lightgray'
                        opacity={0.1 * block.eyeIndicator}
                        stroke='#ddd'
                        strokeWidth={1}
                        shadowColor='black'
                        shadowBlur={2}
                        shadowOffset={{ x: 1, y: 1 }}
                        shadowOpacity={0.4}
                    />
                    <Text
                        key="eye_text_1"
                        text={block.eyeIndicator.toString()}
                        x={block.position[0] - blockSnapSize}
                        y={block.position[1] + blockSnapSize * (block.isEyeDirectionDown ? 1 : -2)}
                        fontSize={isScaled ? 15 : 30}
                        fontFamily={'Calibri'}
                        fill={'gray'}
                    />
                    {/* <Text
                        key="block_text_2"
                        text={block.position != null ? block.position[0]?.toString() + " : " + block.position[1]?.toString() : ""}
                        x={block.position[0]}
                        y={block.position[1]}
                        fontSize={30}
                        fontFamily={'Calibri'}
                        fill={'black'}
                    />
                    <Text
                        key="mouse_position"
                        text={mousePosition ? mousePosition[0] + " : " + mousePosition[1] : ""}
                        x={0}
                        y={height - 35}
                        fontSize={15}
                        fontFamily={'Calibri'}
                        fill={'green'}
                    /> */}

                </Layer>
            </Stage>

            <ActionsStateList
                open={isOpenActionsState}
                actionsState={players.map((p) => p.actionsState).flat()}
                onClose={useCallback(() => { setIsOpenActionsState(false) }, [setIsOpenActionsState])}
                onDeleteActionState={onDeleteActionState}
            />
            <ExportSuccessDialog open={showExportDialog} onHandleClose={useCallback(() => setShowExportDialog(false), [setShowExportDialog])} />
            <Tutorial open={showTutorial} onClose={useCallback(() => { setShowTutorial(false) }, [setShowTutorial])} />
            {fullScreen && !isOnItself &&
                <Box
                    component="section"
                    sx={{
                        height: toggleSpeedButtonHeight,
                        border: '1px dashed grey',
                        borderRadius: 1,
                        bgcolor: 'primary.main',
                        '&:hover': {
                            bgcolor: 'primary.dark',
                        },
                        alignContent: 'center',
                    }}
                    onClick={toggleSpeed}
                >
                    <Typography fontFamily="monospace" fontSize="xx-large" variant="body2" color="text.secondary" align="center">
                        {isSlow ? "Tap to make speed normal" : "Tap to slow it down"}
                    </Typography>
                </Box>
            }
        </div>
    );
}

export default Canvas;
