import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material"
import Link from '@mui/material/Link';
import React, { useCallback, useEffect, useState } from "react"
import Typography from '@mui/material/Typography';
import { Rect } from "react-konva";


type TutorialProps = {
    open: boolean,
    onClose: () => void;
}

const IS_TUTORIAL_SHOWN_KEY = "isTutorialShown";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export const Tutorial = (props: TutorialProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const [isTutorialShown, setIsTutorialShown] = useState(true);

    const [tabValue, setTabValue] = React.useState(0);

    const handleChange = useCallback((event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    }, [setTabValue]);


    useEffect(() => {
        const items = localStorage.getItem(IS_TUTORIAL_SHOWN_KEY);
        if (items === null) {
            setIsTutorialShown(false);
        }
    }, [setIsTutorialShown]);

    return <Dialog
        open={!isTutorialShown || props.open}
        onClose={useCallback(() => {
            setIsTutorialShown(true);
            props.onClose();
        }, [props.onClose, setIsTutorialShown])}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        fullScreen={fullScreen}
    >
        <DialogTitle id="scroll-dialog-title">
            <Typography variant="h3" component="h1" gutterBottom align="center">
                About
            </Typography>
        </DialogTitle>
        <DialogContent dividers={true}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={tabValue}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example">
                    <Tab label="About" {...a11yProps(0)} />
                    <Tab label="Objects" {...a11yProps(1)} />
                    <Tab label="How to train" {...a11yProps(2)} />
                    <Tab label="Can I try it?" {...a11yProps(3)} />
                </Tabs>
            </Box>
            <CustomTabPanel value={tabValue} index={0}>
                <Typography variant="body1" color="text.primary">
                    The main goal of the app is just to have a fun playground to train LSTM\Transformers on sequence-to-sequence tasks.
                    <Divider sx={{ borderColor: "white", height: 15 }} />
                    The current model is an <b><i>LSTM based Encoder-Decoder network with Attention</i></b> what generates a sequence of actions that the red block applies.
                    <br/>
                    <br/>
                    <Divider sx={{ borderColor: "black", height: 5 }} >Have a question\suggestion?</Divider>
                    <br/>
                    Rich out to me via email at <Link href="mailto:info@2x2.site">info@2x2.site</Link>
                </Typography>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={1}>
                <Typography variant="body1" color="text.primary">
                    - <b>Red rectangular</b> - is a main object you are supposed to train.
                    <br />&nbsp;&nbsp;Click on any place and it follows you clicks.
                </Typography>
                <Divider sx={{ borderColor: "white", height: 3 }} />
                <Typography variant="body1" color="text.primary">
                    - <b>Green one</b> - a moving object, collision with Red one makes it disappear and it counts as a hit.
                    <br/> Be cautious not to interact with a gray bar, otherwise it counts as a miss.
                </Typography>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={2}>
                <Typography variant="body1" color="text.primary">
                    - Press "space" button or, in case of a mobile device,
                    <br />&nbsp;&nbsp;tap on the Blue bar <b>to slow down the time</b>.
                    <br />&nbsp;&nbsp;<b>Once time is slowed down. Click\tap on the screen and Red follows you click.</b>
                    <Divider sx={{ borderColor: "white", height: 3 }} />
                    - Aftre playing with it, in slow motion, for about 3-6 minutes,
                    <br />&nbsp;&nbsp;do export the tracked data.
                    <br />&nbsp;&nbsp;Go to menu and click on <b>"EXPORT THE PLAYING HISTORY"</b>.
                    <Divider sx={{ borderColor: "white", height: 3 }} />
                    - All the tracked data are used to train your model.
                    <br />&nbsp;&nbsp;Once your model is ready, you will get notification.
                    <br />
                    <br />
                    <Divider sx={{ borderColor: "black", height: 3 }} />
                    <br />
                    The functionality of training is not automated due to lack of resources.
                    <br/>
                    Send a friendly email with the subject - <i>"I'd like to see my model trained"</i> via email at <Link href="mailto:info@2x2.site?subject=I'd like to see my model trained">info@2x2.site</Link>
                </Typography>
            </CustomTabPanel>
            <CustomTabPanel value={tabValue} index={3}>
                To see how the model works, just hit the <b>LET IT PLAY ON ITS OWN</b> button.
            </CustomTabPanel>
        </DialogContent>
        <DialogActions>
            <Button
                autoFocus
                variant="outlined"
                onClick={useCallback(() => {
                    localStorage.setItem(IS_TUTORIAL_SHOWN_KEY, "1");
                    setIsTutorialShown(true);
                    props.onClose();
                }, [props.onClose, setIsTutorialShown])}>
                {!isTutorialShown ? "Ok, don't show it again" : "Close"}
            </Button>
        </DialogActions>
    </Dialog>
}
