import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, List, ListItem, ListItemText } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';

import { ActionState } from "../Types"


type ActionsStateListProps = {
    actionsState: ActionState[];
    open: boolean;
    onClose: () => void;
    onDeleteActionState: (id: string) => void;
}

export const ActionsStateList = (props: ActionsStateListProps) => {
    return <Dialog
        open={props.open}
        onClose={props.onClose}
        scroll={"paper"}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
    >
        <DialogTitle id="scroll-dialog-title">User actions</DialogTitle>
        <DialogContent dividers={true}>
            <List>
                {props.actionsState.map((actionState, idx) =>
                    <ListItem
                        key={idx}
                        secondaryAction={<IconButton aria-label="delete" onClick={() => props.onDeleteActionState(actionState.id)}>
                            <DeleteIcon />
                        </IconButton>}>
                        <ListItemText primary={actionState.items.map((i) => `${i.playerAction[0]}:${i.playerAction[1]}`).join(", ")} />
                    </ListItem>
                )}
            </List>;
        </DialogContent>
    </Dialog>
}
