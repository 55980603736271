import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import SignInSide from './SignInSide';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, CardActionArea, CardActions, Divider } from '@mui/material';
import Grid from '@mui/material/Grid';

// https://react-icons.github.io/react-icons/icons?name=fc
import { FcOvertime, FcSerialTasks } from 'react-icons/fc';
import CalculateIcon from '@mui/icons-material/Calculate';
import { useNavigate } from 'react-router-dom';


function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">      
      <Link href="mailto:info@2x2.site">
        Send an email to <b>info@2x2.site</b>
      </Link>
      <br/>
      <span>
        <Link color="inherit" href="https://2x2.site/">
          2x2.site
        </Link>{' '}
      </span>
      {new Date().getFullYear()}.
    </Typography>
  );
}

export default function App() {
  const navigate = useNavigate();

  const isTokenExpired = (token: string) => {
    if (!token) {
      return true;
    }
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    const { exp } = JSON.parse(jsonPayload);
    const expired = Date.now() >= exp * 1000
    return expired
  }
  const token = localStorage.getItem("jwt");
  if (token == null || isTokenExpired(token)) {
    return <SignInSide />;
  }

  return (
    <Container maxWidth="sm">
      <Box sx={{ flexGrow: 1, minHeight: '98vh' }} >
        <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 4, md: 8 }}>
          {/* <Grid item xs={2} sm={4} md={4}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea key={"Card_1"} onClick={() => {navigate("/bulat")}}>
                <CardContent  key={"Card_2"}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <FcOvertime size={250} color="green" title='Private' />
                  </div>
                  <Typography gutterBottom variant="h5" component="div">
                    Irregular Verbs
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid> */}
          {/* <Grid item xs={2} sm={4} md={4}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea onClick={() => {navigate("/game")}}>
                <CardContent >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <FcOvertime size={250} color="green" title='Private' />
                  </div>
                  <Typography gutterBottom variant="h5" component="div">
                    Game
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid> */}
          {/* <Grid item xs={2} sm={4} md={4}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea onClick={() => {navigate("/game_headed")}}>
                <CardContent >
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <FcOvertime size={250} color="green" title='Private' />
                  </div>
                  <Typography gutterBottom variant="h5" component="div">
                    Game 2
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid> */}
          <Grid item xs={2} sm={4} md={4}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea onClick={() => { navigate("/rectangular") }}>
                <CardMedia
                  component="img"
                  height="240"
                  image="/rectangular.jpg"
                  alt="green iguana"
                  // sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                />
                <CardContent >
                  {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <FcSerialTasks size={250} color="green" title='Private' />
                  </div> */}
                  <Typography gutterBottom variant="h5" component="div">
                    Game based on LSTM\Transformers network
                  </Typography>
                  <Typography variant="body2" color="text.secondary" fontFamily={'cursive'}>
                    A fun playground to train <b>LSTM</b> and <b>Transformers</b> models
                  </Typography>
                  <br />
                  <Typography variant="body2" color="text.secondary">
                    Teach a rectangular object to react on moving objects
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
          {/* <Grid item xs={2} sm={4} md={4}>
            <Card sx={{ maxWidth: 345 }}>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image="/static/images/cards/contemplative-reptile.jpg"
                  alt="green iguana"
                />
                <CardContent >
                  <CalculateIcon fontSize='large' />
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <FcOvertime size={250} color="green" title='Private' />
                  </div>
                  <Typography gutterBottom variant="h5" component="div">
                    Lizard
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Lizards are a widespread group of squamate reptiles, with over 6,000
                    species, ranging across all continents except Antarctica
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                <Button size="small" color="primary">
                  Share
                </Button>
              </CardActions>
            </Card>
          </Grid> */}
        </Grid>
      </Box>

      <Copyright />
    </Container>
  );
}
