import { useEffect, useRef } from 'react';


function useInterval(callback: () => void, delay: number, isOnPuase: boolean, dependencies: any[] | null | undefined = null) {
    const intervalRef = useRef<number>();
    const callbackRef = useRef(callback);

    useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    useEffect(() => {
        if (isOnPuase) {
            return;
        }
        if (typeof delay === 'number') {
            intervalRef.current = window.setInterval(() => callbackRef.current(), delay);
            return () => window.clearInterval(intervalRef.current);
        }
    }, [delay, isOnPuase, ...(dependencies ? dependencies : [])]);

    return intervalRef;
}

export default useInterval;
