import { useMemo } from "react";

const useAccountRoles = (): [roles: string[] | null] => {
    const jwt = localStorage.getItem("jwt");
    if (jwt == null) {
        console.error("JWT not found");
        window.location.href = "/";
        return [null]
    }

    const json = useMemo(() => parseJwt(jwt), [jwt]);
    return [json.role]
}

function parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export default useAccountRoles;
