import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { Button, Divider, List, ListItem, ListItemButton } from '@mui/material';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CloseIcon from '@mui/icons-material/Close';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import ResumeIcon from '@mui/icons-material/NotStarted';


interface DrawerListProps {
    onToggleDrawer: (newOpen: boolean) => void;
    onResume: () => void;
    onExport: () => void;
}

const DrawerList = (props: DrawerListProps) => {
    return <Box sx={{ width: 250 }} role="presentation">
      <List>
        <ListItem key={"export"} disablePadding>
            <ListItemButton onClick={props.onExport}>
                <ListItemIcon>
               <ImportExportIcon />
                </ListItemIcon>
                <ListItemText primary={"EXPORT THE PLAYING HISTORY"} />
            </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem key={"resume"} disablePadding>
                <ListItemButton onClick={props.onResume}>
                    <ListItemIcon>
                        <ResumeIcon /> 
                    </ListItemIcon>
                    <ListItemText primary={"RESUME"}/>
                </ListItemButton>
          </ListItem>
        <ListItem key={"close"} disablePadding>
                <ListItemButton onClick={() => props.onToggleDrawer(false)}>
                    <ListItemIcon>
                        <CloseIcon /> 
                    </ListItemIcon>
                    <ListItemText primary={"CLOSE"}/>
                </ListItemButton>
          </ListItem>
      </List>
    </Box>
};

export default DrawerList;
  