
import React from "react"
import Typography from '@mui/material/Typography';

type ScoreProps = {
    hits: number,
    misses: number
}

export const Score = (props: ScoreProps) => {
    return <div style={{display: "inline-block", verticalAlign: "top"}}>
        <Typography fontFamily="monospace" fontSize="22px" variant="body1" color="info.main" display={"inline"} paddingLeft={"20px"}>
            Hits:
        </Typography>
        <Typography fontFamily="monospace" fontSize="xx-large" variant="body1" color="firebrick" display={"inline"} paddingLeft={"4px"}>
            {props.hits}
        </Typography>
        <Typography fontFamily="monospace" fontSize="22px" variant="body1" color="info.main" display={"inline"} paddingLeft={"25px"}>
            Misses:
        </Typography>
        <Typography fontFamily="monospace" fontSize="xx-large" variant="body1" color="warning.main" display={"inline"}  paddingLeft={"4px"}>
            {props.misses}
        </Typography>
    </div>
}
