import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';


interface ExportSuccessDialogType {
    open: boolean;
    onHandleClose: () => void;
}

export default function ExportSuccessDialog(props: ExportSuccessDialogType) {
    return (
        <Dialog
            open={props.open}
            onClose={props.onHandleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Export"}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Export succeeded
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={props.onHandleClose} autoFocus>Ok</Button>
            </DialogActions>
        </Dialog>
    );
}
