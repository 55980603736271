import { Button, Chip, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Stage, Layer, Line } from 'react-konva';

const Canvas = () => {
    const [tool, setTool] = React.useState('pen');
    const [lines, setLines] = React.useState<any[]>([]);
    const [run, setRun] = React.useState<boolean>(false);
    const [toSent, setToSent] = React.useState<boolean>(false);
    const [currentCoordinates, setCurrentCoordinates] = React.useState<any>({});

    const isDrawing = React.useRef(false);

    useEffect(() => {
        if (!run || !toSent) {
            return;
        }
        if (!lines || lines.length === 0) {
            return;
        }

        const url = new URL('http://127.0.0.1:8000/pred_headed');
        const points = lines.slice(-1)[0].points;

        const d = points.reduce((accumulator: any[], currentValue: any, currentIndex: number, array: any[]) => {
            if (currentIndex % 2 === 0) {
                accumulator.push(array.slice(currentIndex, currentIndex + 2));
            }
            return accumulator;
        }, [])
            .map((p: any[]) => { return [p[0], p[1]] });

        // d.forEach((point: any[]) => url.searchParams.append('coordinates[]', (point[0], point[1])));

        // fetch(`http://127.0.0.1:8000/pred_headed?x=${Math.round(point.x)}&y=${Math.round(point.y)}`)


        fetch(url, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                points: d
            })
        })
            .then((res) => res.json())
            .then((data) => {
                let points = data.map((d: any) => {
                    return [d[0], d[1]]
                });
                let negative_index = null
                for (let i = 0; i < points.length; i++) {
                    if (points[i][0] < 0 || points[i][1] < 0){
                        negative_index = i;
                        break;
                    }
                }
                if (negative_index != null) {
                    points = points.slice(negative_index);
                }
                const new_lines = {
                    tool,
                    points: points.flat()
                };

                setLines([new_lines]);
            });

        setToSent(false);
    }, [run, lines, toSent])

    const handleMouseDown = (e: any) => {
        isDrawing.current = true;
        const pos = e.target.getStage().getPointerPosition();
        setLines([...lines, { tool, points: [pos.x, pos.y] }]);
    };

    const handleMouseMove = (e: any) => {
        const stage = e.target.getStage();
        const point = stage.getPointerPosition();

        setCurrentCoordinates({x: point.x, y: point.y})

        // no drawing - skipping
        if (!isDrawing.current) {
            return;
        }
        let lastLine = lines[lines.length - 1];
        // add point
        lastLine.points = lastLine.points.concat([point.x, point.y]);

        // replace last
        lines.splice(lines.length - 1, 1, lastLine);
        setLines(lines.concat());
    };

    const handleMouseUp = () => {
        isDrawing.current = false;
        setToSent(true);
    };


    const handleKeyDown = (e: any) => {
        if (e.key === 'Enter') {
            console.log(lines);
            if (lines.length === 0) {
                return;
            }
            const data = lines.map((line: any, index: number) => {
                const d = line.points.reduce((accumulator: any[], currentValue: any, currentIndex: number, array: any[]) => {
                    if (currentIndex % 2 === 0) {
                        accumulator.push(array.slice(currentIndex, currentIndex + 2));
                    }
                    return accumulator;
                }, [])
                    .map((p: any[]) => { return { x: p[0], y: p[1] } });
                return {
                    id: index,
                    line: d,
                    len: d.length,
                };
            });
            const blob = new Blob([JSON.stringify(data, null, 2)], { type: 'application/json' });

            saveFile(blob);
        }
    };

    const saveFile = async (blob: any) => {
        const a = document.createElement('a');
        a.download = 'data.json';
        a.href = URL.createObjectURL(blob);
        a.addEventListener('click', (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
    };

    return (
        <div tabIndex={1} onKeyDown={handleKeyDown}>
            <Button variant="outlined" onClick={() => { setRun(v => !v); }}>{(run ? "Stop" : "Run")}</Button>
            <Stage
                width={window.innerWidth-50}
                height={window.innerHeight - 115}
                onMouseDown={handleMouseDown}
                onMousemove={handleMouseMove}
                onMouseup={handleMouseUp}
            >
                <Layer>
                    {lines.map((line, i) => (
                        <Line
                            key={i}
                            points={line.points}
                            stroke="#df4b26"
                            strokeWidth={5}
                            tension={0.5}
                            lineCap="round"
                            lineJoin="round"
                            globalCompositeOperation={
                                line.tool === 'eraser' ? 'destination-out' : 'source-over'
                            }
                        />
                    ))}
                </Layer>
                
            </Stage>
            <select
                value={tool}
                onChange={(e) => {
                    setTool(e.target.value);
                }}
            >
                <option value="pen">Pen</option>
                <option value="eraser">Eraser</option>
            </select>
                <Stack direction="row" spacing={1}>
                    <Chip label={Math.round(currentCoordinates.x) + ":" + Math.round(currentCoordinates.y)} color="primary" />
                </Stack>
        </div>
    );
};

export default Canvas
