import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import { useNavigate } from "react-router-dom";


export default function Login() {
  const navigate = useNavigate();

  React.useEffect(() => {
    if (window.location.hash == null) {
      alert("Not authorized")
      return;
    }
    const token = window.location.hash.slice(1);
    localStorage.setItem("jwt", token);
    const url = process.env.REACT_APP_BACKEND + "/Auth/me";
    fetch(url, {
      headers: new Headers({
        'Authorization': 'Bearer ' + token,
        'Content-Type': 'application/json'
      }),
    })
      .then(response => response.json())
      .then(json => {
        localStorage.setItem("user", JSON.stringify(json));
      })
      .catch(error => console.error(error));

    return navigate("/");
  }, []);

  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Logging In
        </Typography>
      </Box>
    </Container>
  );
}
