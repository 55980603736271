import { createSlice, nanoid } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store'

export interface Item {
    id: string
    verb: string
    verb_examples: string[]
    past_simple: string
    past_simple_examples: string[]
    past_participle: string
    past_participle_examples: string[]
    text: string
    links:string[]
}

// Define the initial state using that type
const initialState: Item[] = [
    { id: "1", verb: "be", verb_examples: ["I am a huge fan."], past_simple: "was / were", past_simple_examples: ["I was a huge fan."], past_participle: "been", past_participle_examples: ["I have been a fan of him for a long time."], text: "", links: ["https://dictionary.cambridge.org/ru/%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/%D0%B1%D1%80%D0%B8%D1%82%D0%B0%D0%BD%D1%81%D0%BA%D0%B0%D1%8F-%D0%B3%D1%80%D0%B0%D0%BC%D0%BC%D0%B0%D1%82%D0%B8%D0%BA%D0%B0/be"]},
    { id: "2", verb: "begin", verb_examples: ["The film they want to watch begins at seven."], past_simple: "began", past_simple_examples: ["The meeting began promisingly, but then things started to go wrong."], past_participle: "begun", past_participle_examples: ["Jane has just begun learning to drive."], text: "", links: ["https://dictionary.cambridge.org/ru/%D1%81%D0%BB%D0%BE%D0%B2%D0%B0%D1%80%D1%8C/%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9/begin"]},
    { id: "3", verb: "do / does", verb_examples: ["Can I do anything to help?", "He usually does his homework in front of the television."], past_simple: "did", past_simple_examples: ["I did some shopping this morning."], past_participle: "done", past_participle_examples: ["Have you done your essay yet?"], text: "", links: ["https://dictionary.cambridge.org/grammar/british-grammar/do"]},
    { id: "4", verb: "have / has", verb_examples: ["We usually have breakfast at about eight.", "She has quite dark hair."], past_simple: "had", past_simple_examples: ["I had a strange dream last night."], past_participle: "had", past_participle_examples: ["I have had a headache all day."], text: "", links: ["https://dictionary.cambridge.org/grammar/british-grammar/have", "https://www.espressoenglish.net/have-had-and-had-had/"]},
    { id: "5", verb: "eat", verb_examples: ["Do you eat meat?"], past_simple: "ate", past_simple_examples: ["He ate a hamburger for lunch."], past_participle: "had", past_participle_examples: ["When all of the eggs had been eaten, the remaining foam was often spread on tree bark or leaves."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/eat"]},
    { id: "6", verb: "go", verb_examples: ["Does this train go to Newcastle?"], past_simple: "went", past_simple_examples: ["I went to Paris last summer. Have you ever been there?"], past_participle: "had", past_participle_examples: ["I've never gone skiing."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/go"]},
    { id: "7", verb: "get", verb_examples: ["He went to the shop to get some milk."], past_simple: "got", past_simple_examples: ["This window got broken."], past_participle: "got / gotten", past_participle_examples: ["Have the police got the man who did it yet?"], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/get"]},
    { id: "8", verb: "break", verb_examples: ["I didn't mean to break your phone."], past_simple: "broke", past_simple_examples: ["She fell and broke her arm"], past_participle: "broken", past_participle_examples: ["I think I've broken your phone."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/break"]},
    { id: "9", verb: "become", verb_examples: ["The situation becomes as serious as it is with other."], past_simple: "became", past_simple_examples: ["After giving up smoking, he became fat and irritable."], past_participle: "become", past_participle_examples: ["He has just become a father."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/become"]},
    { id: "10", verb: "give", verb_examples: ["Can you give me a date for another appointment?"], past_simple: "gave", past_simple_examples: ["They never gave me a chance/choice."], past_participle: "given", past_participle_examples: ["Has the director given you permission to do that?"], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/give"]},
    { id: "11", verb: "build", verb_examples: ["They're building new houses by the river."], past_simple: "built", past_simple_examples: ["The birds built their nest in the tree."], past_participle: "built", past_participle_examples: ["Researchers have built a sophisticated computer program to search for answers."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/build"]},
    { id: "12", verb: "come", verb_examples: ["Are you coming with me?"], past_simple: "came", past_simple_examples: ["We came by car."], past_participle: "come", past_participle_examples: ["A wire has come loose at the back."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/come"]},
    { id: "13", verb: "cut", verb_examples: ["Cut the meat up into small pieces."], past_simple: "cut", past_simple_examples: ["I cut myself on that glass."], past_participle: "cut", past_participle_examples: ["We have cut our wage bill from £12,000 to £8,000 per week."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/cut"]},
    { id: "14", verb: "forgive", verb_examples: ["I'd never forgive myself if anything happened to the kids."], past_simple: "forgave", past_simple_examples: ["He forgave his brother for breaking the window."], past_participle: "forgiven", past_participle_examples: ["I don't think she's ever quite forgiven me for getting her name wrong that time."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/forgive"]},
    { id: "15", verb: "teach", verb_examples: ["She is teaching me French."], past_simple: "taught", past_simple_examples: ["Who taught you to cook?"], past_participle: "taught", past_participle_examples: ["I have taught English for the last 30 days"], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/teach", "https://forum.wordreference.com/threads/i-taught-or-i-have-taught-english.1274906/"]},
    { id: "16", verb: "learn", verb_examples: ["They learn Russian at school."], past_simple: "learnt / learned", past_simple_examples: ["I learned to drive when I was 16."], past_participle: "learnt / learned", past_participle_examples: ["I've learned a lot about computers since I started work here."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/learn"]},
    { id: "17", verb: "catch", verb_examples: ["I couldn't catch what the announcer said, with all the other noise going on."], past_simple: "caught", past_simple_examples: ["He caught hold of my arm."], past_participle: "caught", past_participle_examples: ["I have caught a big \"bronzy\" - a serious fish."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/catch", "https://app.ludwig.guru/s/I+have+caught"]},
    { id: "18", verb: "choose", verb_examples: ["Danny, come here and choose your ice cream."], past_simple: "chose", past_simple_examples: ["Yesterday the selectors chose Dales as the team's new captain."], past_participle: "chosen", past_participle_examples: ["I've chosen a present for Luis."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/choose"]},
    { id: "19", verb: "cost", verb_examples: ["\"How much does this book cost?\" \"It costs £25.\""], past_simple: "cost", past_simple_examples: [""], past_participle: "cost", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/cost"]},
    { id: "20", verb: "buy", verb_examples: ["Eventually she had saved enough money to buy a small car."], past_simple: "bought", past_simple_examples: ["I bought my camera from a friend of mine."], past_participle: "bought", past_participle_examples: ["And I have bought so many nice little dresses."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/buy"]},
    { id: "21", verb: "dig", verb_examples: ["I was planning to go out and dig up some hibiscus plants."], past_simple: "dug", past_simple_examples: ["We dug a hole and planted the tree."], past_participle: "dug", past_participle_examples: ["\"I have dug a great big hole for myself,\" he said philosophically."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/dig", "https://app.ludwig.guru/s/i+have+dug"]},
    { id: "22", verb: "draw", verb_examples: ["Jonathan can draw very well."], past_simple: "drew", past_simple_examples: ["The children drew pictures of their families."], past_participle: "drawn", past_participle_examples: ["I have drawn since 1985."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/draw"]},
    { id: "23", verb: "find", verb_examples: ["I couldn't find Andrew's phone number."], past_simple: "found", past_simple_examples: ["The study found that men who were married lived longer than those who were not."], past_participle: "found", past_participle_examples: ["I've just found a ten-pound note in my pocket."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/find"]},
    { id: "24", verb: "drink", verb_examples: ["The animals came down to the waterhole to drink."], past_simple: "drank", past_simple_examples: ["He drank three glasses of water."], past_participle: "drunk", past_participle_examples: ["I have drunk"], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/drink"]},
    { id: "25", verb: "drive", verb_examples: ["I'm learning to drive.", "She drives a red sports car."], past_simple: "drove", past_simple_examples: ["I drove my daughter to school."], past_participle: "driven", past_participle_examples: ["Recent events have driven the stock market to lows not seen in ten years."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/drive"]},
    { id: "26", verb: "fly", verb_examples: ["The poor bird couldn't fly because it had a broken wing."], past_simple: "flew", past_simple_examples: ["We flew to Paris."], past_participle: "flown", past_participle_examples: ["My holiday seems to have flown (by) (= passed very quickly) this year."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/fly"]},
    { id: "27", verb: "dream", verb_examples: ["I often dream about/of flying."], past_simple: "dreamed / dreamt", past_simple_examples: ["I dreamed that I was having a baby."], past_participle: "dreamed / dreamt", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/dream"]},
    { id: "28", verb: "forget", verb_examples: ["I never forget a face (= I'm good at remembering people)."], past_simple: "forgot", past_simple_examples: ["I forgot my keys."], past_participle: "forgotten", past_participle_examples: ["I'm sorry, I've forgotten your name."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/forget"]},
    { id: "29", verb: "write", verb_examples: ["Why not write (down) your ideas on a piece of paper before you start?"], past_simple: "wrote", past_simple_examples: ["He wrote music for films and TV shows."], past_participle: "written", past_participle_examples: ["I've written twice and received no reply, so I might change tack and call her."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/write"]},
    { id: "30", verb: "think", verb_examples: ["I think (that) I've met you before."], past_simple: "thought", past_simple_examples: ["I always thought he was a bit weird."], past_participle: "thought", past_participle_examples: ["I have thought about this recently."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/think", "https://ell.stackexchange.com/questions/90266/i-have-thought-or-i-thought"]},
    { id: "31", verb: "understand", verb_examples: ["My wife doesn't understand me."], past_simple: "understood", past_simple_examples: ["When he said three o'clock, I understood him to mean in the afternoon."], past_participle: "understood", past_participle_examples: ["After what's happened with my husband, I have understood that they are right."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/understand", "https://context.reverso.net/%D0%BF%D0%B5%D1%80%D0%B5%D0%B2%D0%BE%D0%B4/%D0%B0%D0%BD%D0%B3%D0%BB%D0%B8%D0%B9%D1%81%D0%BA%D0%B8%D0%B9-%D1%80%D1%83%D1%81%D1%81%D0%BA%D0%B8%D0%B9/I+have+understood"]},
    { id: "32", verb: "speak", verb_examples: ["Can I speak with Scott please?"], past_simple: "spoke", past_simple_examples: ["She spoke of her sadness over her father's death."], past_participle: "spoken", past_participle_examples: ["I have spoken with the president many times about this."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/speak"]},
    { id: "33", verb: "read", verb_examples: ["Your handwriting is so untidy I can't read it."], past_simple: "read", past_simple_examples: ["I read about the family's success in the local paper."], past_participle: "read", past_participle_examples: ["You really have read my paper."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/read"]},
    { id: "34", verb: "stick", verb_examples: ["I tried to stick the pieces together with some glue/tape."], past_simple: "stuck", past_simple_examples: ["She stuck her fingers in her ears so that she couldn't hear the noise."], past_participle: "stuck", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/stick"]},
    { id: "35", verb: "take", verb_examples: ["If you take 4 (away) from 12 you get 8."], past_simple: "took", past_simple_examples: ["Here's your pen - I took it by mistake."], past_participle: "taken", past_participle_examples: ["In the dim light I could have taken them for brothers."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/take"]},
    { id: "36", verb: "set", verb_examples: [""], past_simple: "set", past_simple_examples: [""], past_participle: "set", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/set"]},
    { id: "37", verb: "sing", verb_examples: [""], past_simple: "sang", past_simple_examples: [""], past_participle: "sung", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/sing"]},
    { id: "38", verb: "spend", verb_examples: [""], past_simple: "spent", past_simple_examples: [""], past_participle: "spent", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/spend"]},
    { id: "39", verb: "steal", verb_examples: [""], past_simple: "stole", past_simple_examples: [""], past_participle: "stolen", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/steal"]},
    { id: "40", verb: "hear", verb_examples: [""], past_simple: "heard", past_simple_examples: [""], past_participle: "heard", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/hear"]},
    { id: "41", verb: "arise", verb_examples: ["If the opportunity arises, I'd love to go to China."], past_simple: "arose", past_simple_examples: [""], past_participle: "arisen", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/arise"]},
    { id: "42", verb: "beat", verb_examples: ["Simon always beats me at tennis."], past_simple: "beat", past_simple_examples: [""], past_participle: "beaten", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/beat"]},
    { id: "43", verb: "know", verb_examples: ["\"Where did he go?\" \"I don't know.\""], past_simple: "knew", past_simple_examples: ["I just knew (that) it was going to be a disaster."], past_participle: "known", past_participle_examples: ["I've known Daniel since we were at school together."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/know"]},
    { id: "44", verb: "make", verb_examples: ["She makes all her own clothes."], past_simple: "made", past_simple_examples: ["He made a chocolate cake."], past_participle: "made", past_participle_examples: ["The president has made Henry Paulson his Secretary of the Treasury."], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/make"]},
    { id: "45", verb: "put", verb_examples: ["If you put together (= mix) yellow and blue paint you get green."], past_simple: "put", past_simple_examples: ["He put salt into the sugar bowl by mistake."], past_participle: "put", past_participle_examples: ["Where have you put the keys?"], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/put"]},
    { id: "46", verb: "bend", verb_examples: [""], past_simple: "bent", past_simple_examples: [""], past_participle: "bent", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/bend"]},
    { id: "47", verb: "bite", verb_examples: [""], past_simple: "bit", past_simple_examples: [""], past_participle: "bitten", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/bite"]},
    { id: "48", verb: "see", verb_examples: [""], past_simple: "saw", past_simple_examples: [""], past_participle: "seen", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/see"]},
    { id: "49", verb: "arise", verb_examples: [""], past_simple: "arose", past_simple_examples: [""], past_participle: "arisen", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/arise"]},
    { id: "50", verb: "be", verb_examples: [""], past_simple: "was/were", past_simple_examples: [""], past_participle: "been", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/be"]},
    { id: "51", verb: "beat", verb_examples: [""], past_simple: "beat", past_simple_examples: [""], past_participle: "beaten", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/beat"]},
    { id: "52", verb: "become", verb_examples: [""], past_simple: "became", past_simple_examples: [""], past_participle: "become", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/become"]},
    { id: "53", verb: "begin", verb_examples: [""], past_simple: "began", past_simple_examples: [""], past_participle: "begun", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/begin"]},
    { id: "54", verb: "bend", verb_examples: [""], past_simple: "bent", past_simple_examples: [""], past_participle: "bent", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/bend"]},
    { id: "55", verb: "bite", verb_examples: [""], past_simple: "bit", past_simple_examples: [""], past_participle: "bitten", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/bite"]},
    { id: "56", verb: "bleed", verb_examples: [""], past_simple: "bled", past_simple_examples: [""], past_participle: "bled", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/bleed"]},
    { id: "57", verb: "blow", verb_examples: [""], past_simple: "blew", past_simple_examples: [""], past_participle: "blown", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/blow"]},
    { id: "58", verb: "break", verb_examples: [""], past_simple: "broke", past_simple_examples: [""], past_participle: "broken", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/break"]},
    { id: "59", verb: "bring", verb_examples: [""], past_simple: "brought", past_simple_examples: [""], past_participle: "brought", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/bring"]},
    { id: "60", verb: "broadcast", verb_examples: [""], past_simple: "broadcast", past_simple_examples: [""], past_participle: "broadcast", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/broadcast"]},
    { id: "61", verb: "build", verb_examples: [""], past_simple: "built", past_simple_examples: [""], past_participle: "built", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/build"]},
    { id: "62", verb: "burn", verb_examples: [""], past_simple: "burnt", past_simple_examples: [""], past_participle: "burnt", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/burn"]},
    { id: "63", verb: "burst", verb_examples: [""], past_simple: "burst", past_simple_examples: [""], past_participle: "burst", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/burst"]},
    { id: "64", verb: "buy", verb_examples: [""], past_simple: "bought", past_simple_examples: [""], past_participle: "bought", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/buy"]},
    { id: "65", verb: "catch", verb_examples: [""], past_simple: "caught", past_simple_examples: [""], past_participle: "caught", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/catch"]},
    { id: "66", verb: "choose", verb_examples: [""], past_simple: "chose", past_simple_examples: [""], past_participle: "chosen", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/choose"]},
    { id: "67", verb: "come", verb_examples: [""], past_simple: "came", past_simple_examples: [""], past_participle: "come", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/come"]},
    { id: "68", verb: "cost", verb_examples: [""], past_simple: "cost", past_simple_examples: [""], past_participle: "cost", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/cost"]},
    { id: "69", verb: "creep", verb_examples: [""], past_simple: "crept", past_simple_examples: [""], past_participle: "crept", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/creep"]},
    { id: "70", verb: "cut", verb_examples: [""], past_simple: "cut", past_simple_examples: [""], past_participle: "cut", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/cut"]},
    { id: "71", verb: "deal", verb_examples: [""], past_simple: "dealt", past_simple_examples: [""], past_participle: "dealt", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/deal"]},
    { id: "72", verb: "dig", verb_examples: [""], past_simple: "dug", past_simple_examples: [""], past_participle: "dug", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/dig"]},
    { id: "73", verb: "do", verb_examples: [""], past_simple: "did", past_simple_examples: [""], past_participle: "done", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/do"]},
    { id: "74", verb: "draw", verb_examples: [""], past_simple: "drew", past_simple_examples: [""], past_participle: "drawn", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/draw"]},
    { id: "75", verb: "dream", verb_examples: [""], past_simple: "dreamt", past_simple_examples: [""], past_participle: "dreamt", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/dream"]},
    { id: "76", verb: "drink", verb_examples: [""], past_simple: "drank", past_simple_examples: [""], past_participle: "drunk", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/drink"]},
    { id: "77", verb: "drive", verb_examples: [""], past_simple: "drove", past_simple_examples: [""], past_participle: "driven", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/drive"]},
    { id: "78", verb: "eat", verb_examples: [""], past_simple: "ate", past_simple_examples: [""], past_participle: "eaten", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/eat"]},
    { id: "79", verb: "fall", verb_examples: [""], past_simple: "fell", past_simple_examples: [""], past_participle: "fallen", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/fall"]},
    { id: "80", verb: "feed", verb_examples: [""], past_simple: "fed", past_simple_examples: [""], past_participle: "fed", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/feed"]},
    { id: "81", verb: "feel", verb_examples: [""], past_simple: "felt", past_simple_examples: [""], past_participle: "felt", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/feel"]},
    { id: "82", verb: "fight", verb_examples: [""], past_simple: "fought", past_simple_examples: [""], past_participle: "fought", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/fight"]},
    { id: "83", verb: "find", verb_examples: [""], past_simple: "found", past_simple_examples: [""], past_participle: "found", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/find"]},
    { id: "84", verb: "fly", verb_examples: [""], past_simple: "flew", past_simple_examples: [""], past_participle: "flown", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/fly"]},
    { id: "85", verb: "forbid", verb_examples: [""], past_simple: "forbade", past_simple_examples: [""], past_participle: "forbidden", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/forbid"]},
    { id: "86", verb: "forget", verb_examples: [""], past_simple: "forgot", past_simple_examples: [""], past_participle: "forgotten", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/forget"]},
    { id: "87", verb: "forgive", verb_examples: [""], past_simple: "forgave", past_simple_examples: [""], past_participle: "forgiven", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/forgive"]},
    { id: "88", verb: "freeze", verb_examples: [""], past_simple: "froze", past_simple_examples: [""], past_participle: "frozen", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/freeze"]},
    { id: "89", verb: "get", verb_examples: [""], past_simple: "got", past_simple_examples: [""], past_participle: "got", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/get"]},
    { id: "90", verb: "give", verb_examples: [""], past_simple: "gave", past_simple_examples: [""], past_participle: "given", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/give"]},
    { id: "91", verb: "go", verb_examples: [""], past_simple: "went", past_simple_examples: [""], past_participle: "gone", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/go"]},
    { id: "92", verb: "grow", verb_examples: [""], past_simple: "grew", past_simple_examples: [""], past_participle: "grown", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/grow"]},
    { id: "93", verb: "hang", verb_examples: [""], past_simple: "hung", past_simple_examples: [""], past_participle: "hung", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/hang"]},
    { id: "94", verb: "have", verb_examples: [""], past_simple: "had", past_simple_examples: [""], past_participle: "had", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/have"]},
    { id: "95", verb: "hear", verb_examples: [""], past_simple: "heard", past_simple_examples: [""], past_participle: "heard", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/hear"]},
    { id: "96", verb: "hide", verb_examples: [""], past_simple: "hid", past_simple_examples: [""], past_participle: "hidden", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/hide"]},
    { id: "97", verb: "hit", verb_examples: [""], past_simple: "hit", past_simple_examples: [""], past_participle: "hit", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/hit"]},
    { id: "98", verb: "hold", verb_examples: [""], past_simple: "held", past_simple_examples: [""], past_participle: "held", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/hold"]},
    { id: "99", verb: "hurt", verb_examples: [""], past_simple: "hurt", past_simple_examples: [""], past_participle: "hurt", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/hurt"]},
    { id: "100", verb: "keep", verb_examples: [""], past_simple: "kept", past_simple_examples: [""], past_participle: "kept", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/keep"]},
    { id: "101", verb: "kneel", verb_examples: [""], past_simple: "knelt", past_simple_examples: [""], past_participle: "knelt", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/kneel"]},
    { id: "102", verb: "know", verb_examples: [""], past_simple: "knew", past_simple_examples: [""], past_participle: "known", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/know"]},
    { id: "103", verb: "lay", verb_examples: [""], past_simple: "laid", past_simple_examples: [""], past_participle: "laid", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/lay"]},
    { id: "104", verb: "lead", verb_examples: [""], past_simple: "led", past_simple_examples: [""], past_participle: "led", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/lead"]},
    { id: "105", verb: "lean", verb_examples: [""], past_simple: "leant", past_simple_examples: [""], past_participle: "leant", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/lean"]},
    { id: "106", verb: "learn", verb_examples: [""], past_simple: "learnt", past_simple_examples: [""], past_participle: "learnt", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/learn"]},
    { id: "107", verb: "leave", verb_examples: [""], past_simple: "left", past_simple_examples: [""], past_participle: "left", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/leave"]},
    { id: "108", verb: "lend", verb_examples: [""], past_simple: "lent", past_simple_examples: [""], past_participle: "lent", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/lend"]},
    { id: "109", verb: "let", verb_examples: [""], past_simple: "let", past_simple_examples: [""], past_participle: "let", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/let"]},
    { id: "110", verb: "lie", verb_examples: [""], past_simple: "lay", past_simple_examples: [""], past_participle: "lain", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/lie"]},
    { id: "111", verb: "light", verb_examples: [""], past_simple: "lit", past_simple_examples: [""], past_participle: "lit", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/light"]},
    { id: "112", verb: "lose", verb_examples: [""], past_simple: "lost", past_simple_examples: [""], past_participle: "lost", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/lose"]},
    { id: "113", verb: "make", verb_examples: [""], past_simple: "made", past_simple_examples: [""], past_participle: "made", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/make"]},
    { id: "114", verb: "mean", verb_examples: [""], past_simple: "meant", past_simple_examples: [""], past_participle: "meant", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/mean"]},
    { id: "115", verb: "meet", verb_examples: [""], past_simple: "met", past_simple_examples: [""], past_participle: "met", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/meet"]},
    { id: "116", verb: "pay", verb_examples: [""], past_simple: "paid", past_simple_examples: [""], past_participle: "paid", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/pay"]},
    { id: "117", verb: "put", verb_examples: [""], past_simple: "put", past_simple_examples: [""], past_participle: "put", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/put"]},
    { id: "118", verb: "read", verb_examples: [""], past_simple: "read", past_simple_examples: [""], past_participle: "read", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/read"]},
    { id: "119", verb: "ride", verb_examples: [""], past_simple: "rode", past_simple_examples: [""], past_participle: "ridden", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/ride"]},
    { id: "120", verb: "ring", verb_examples: [""], past_simple: "rang", past_simple_examples: [""], past_participle: "rung", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/ring"]},
    { id: "121", verb: "rise", verb_examples: [""], past_simple: "rose", past_simple_examples: [""], past_participle: "risen", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/rise"]},
    { id: "122", verb: "run", verb_examples: [""], past_simple: "ran", past_simple_examples: [""], past_participle: "run", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/run"]},
    { id: "123", verb: "say", verb_examples: [""], past_simple: "said", past_simple_examples: [""], past_participle: "said", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/say"]},
    { id: "124", verb: "see", verb_examples: [""], past_simple: "saw", past_simple_examples: [""], past_participle: "seen", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/see"]},
    { id: "125", verb: "sell", verb_examples: [""], past_simple: "sold", past_simple_examples: [""], past_participle: "sold", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/sell"]},
    { id: "126", verb: "send", verb_examples: [""], past_simple: "sent", past_simple_examples: [""], past_participle: "sent", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/send"]},
    { id: "127", verb: "set", verb_examples: [""], past_simple: "set", past_simple_examples: [""], past_participle: "set", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/set"]},
    { id: "128", verb: "sew", verb_examples: [""], past_simple: "sewed", past_simple_examples: [""], past_participle: "sewn", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/sew"]},
    { id: "129", verb: "shake", verb_examples: [""], past_simple: "shook", past_simple_examples: [""], past_participle: "shaken", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/shake"]},
    { id: "130", verb: "shine", verb_examples: [""], past_simple: "shone", past_simple_examples: [""], past_participle: "shone", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/shine"]},
    { id: "131", verb: "shoot", verb_examples: [""], past_simple: "shot", past_simple_examples: [""], past_participle: "shot", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/shoot"]},
    { id: "132", verb: "show", verb_examples: [""], past_simple: "showed", past_simple_examples: [""], past_participle: "shown", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/show"]},
    { id: "133", verb: "shrink", verb_examples: [""], past_simple: "shrank", past_simple_examples: [""], past_participle: "shrunk", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/shrink"]},
    { id: "134", verb: "shut", verb_examples: [""], past_simple: "shut", past_simple_examples: [""], past_participle: "shut", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/shut"]},
    { id: "135", verb: "sing", verb_examples: [""], past_simple: "sang", past_simple_examples: [""], past_participle: "sung", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/sing"]},
    { id: "136", verb: "sink", verb_examples: [""], past_simple: "sank", past_simple_examples: [""], past_participle: "sunk", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/sink"]},
    { id: "137", verb: "sit", verb_examples: [""], past_simple: "sat", past_simple_examples: [""], past_participle: "sat", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/sit"]},
    { id: "138", verb: "sleep", verb_examples: [""], past_simple: "slept", past_simple_examples: [""], past_participle: "slept", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/sleep"]},
    { id: "139", verb: "slide", verb_examples: [""], past_simple: "slid", past_simple_examples: [""], past_participle: "slid", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/slide"]},
    { id: "140", verb: "smell", verb_examples: [""], past_simple: "smelt", past_simple_examples: [""], past_participle: "smelt", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/smell"]},
    { id: "141", verb: "sow", verb_examples: [""], past_simple: "sowed", past_simple_examples: [""], past_participle: "sown", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/sow"]},
    { id: "142", verb: "speak", verb_examples: [""], past_simple: "spoke", past_simple_examples: [""], past_participle: "spoken", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/speak"]},
    { id: "143", verb: "spell", verb_examples: [""], past_simple: "spelt/spelled", past_simple_examples: [""], past_participle: "spelt/spelled", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/spell"]},
    { id: "144", verb: "spend", verb_examples: [""], past_simple: "spent", past_simple_examples: [""], past_participle: "spent", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/spend"]},
    { id: "145", verb: "spill", verb_examples: [""], past_simple: "spilt", past_simple_examples: [""], past_participle: "spilt", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/spill"]},
    { id: "146", verb: "spit", verb_examples: [""], past_simple: "spat", past_simple_examples: [""], past_participle: "spat", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/spit"]},
    { id: "147", verb: "split", verb_examples: [""], past_simple: "split", past_simple_examples: [""], past_participle: "split", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/split"]},
    { id: "148", verb: "spoil", verb_examples: [""], past_simple: "spoilt", past_simple_examples: [""], past_participle: "spoilt", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/spoil"]},
    { id: "149", verb: "spread", verb_examples: [""], past_simple: "spread", past_simple_examples: [""], past_participle: "spread", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/spread"]},
    { id: "150", verb: "spring", verb_examples: [""], past_simple: "sprang", past_simple_examples: [""], past_participle: "sprung", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/spring"]},
    { id: "151", verb: "stand", verb_examples: [""], past_simple: "stood", past_simple_examples: [""], past_participle: "stood", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/stand"]},
    { id: "152", verb: "steal", verb_examples: [""], past_simple: "stole", past_simple_examples: [""], past_participle: "stolen", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/steal"]},
    { id: "153", verb: "stick", verb_examples: [""], past_simple: "stuck", past_simple_examples: [""], past_participle: "stuck", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/stick"]},
    { id: "154", verb: "sting", verb_examples: [""], past_simple: "stung", past_simple_examples: [""], past_participle: "stung", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/sting"]},
    { id: "155", verb: "strike", verb_examples: [""], past_simple: "struck", past_simple_examples: [""], past_participle: "struck", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/strike"]},
    { id: "156", verb: "swear", verb_examples: [""], past_simple: "swore", past_simple_examples: [""], past_participle: "sworn", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/swear"]},
    { id: "157", verb: "sweep", verb_examples: [""], past_simple: "swept", past_simple_examples: [""], past_participle: "swept", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/sweep"]},
    { id: "158", verb: "swell", verb_examples: [""], past_simple: "swelled", past_simple_examples: [""], past_participle: "swollen", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/swell"]},
    { id: "159", verb: "swim", verb_examples: [""], past_simple: "swam", past_simple_examples: [""], past_participle: "swum", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/swim"]},
    { id: "160", verb: "swing", verb_examples: [""], past_simple: "swung", past_simple_examples: [""], past_participle: "swung", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/swing"]},
    { id: "161", verb: "take", verb_examples: [""], past_simple: "took", past_simple_examples: [""], past_participle: "taken", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/take"]},
    { id: "162", verb: "teach", verb_examples: [""], past_simple: "taught", past_simple_examples: [""], past_participle: "taught", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/teach"]},
    { id: "163", verb: "tear", verb_examples: [""], past_simple: "tore", past_simple_examples: [""], past_participle: "torn", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/tear"]},
    { id: "164", verb: "tell", verb_examples: [""], past_simple: "told", past_simple_examples: [""], past_participle: "told", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/tell"]},
    { id: "165", verb: "think", verb_examples: [""], past_simple: "thought", past_simple_examples: [""], past_participle: "thought", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/think"]},
    { id: "166", verb: "throw", verb_examples: [""], past_simple: "threw", past_simple_examples: [""], past_participle: "thrown", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/throw"]},
    { id: "167", verb: "understand", verb_examples: [""], past_simple: "understood", past_simple_examples: [""], past_participle: "understood", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/understand"]},
    { id: "168", verb: "wake", verb_examples: [""], past_simple: "woke", past_simple_examples: [""], past_participle: "woken", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/wake"]},
    { id: "169", verb: "wear", verb_examples: [""], past_simple: "wore", past_simple_examples: [""], past_participle: "worn", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/wear"]},
    { id: "170", verb: "weep", verb_examples: [""], past_simple: "wept", past_simple_examples: [""], past_participle: "wept", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/weep"]},
    { id: "171", verb: "win", verb_examples: [""], past_simple: "won", past_simple_examples: [""], past_participle: "won", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/win"]},
    { id: "172", verb: "write", verb_examples: [""], past_simple: "wrote", past_simple_examples: [""], past_participle: "written", past_participle_examples: [""], text: "", links: ["https://dictionary.cambridge.org/dictionary/english/write"]},
]

export const vocabularySlice = createSlice({
    name: 'vocabulary',
    initialState,
    reducers: {
        addItem: {
            reducer: (state, action: PayloadAction<Item>) => {
                state.push(action.payload)
            },
            prepare: (verb: string, verb_examples: [string], past_simple: string, past_simple_examples: [string], past_participle: string, past_participle_examples: [string], text: string, links: [string]) => {
                const id = nanoid()
                return { payload: { id, verb, verb_examples, past_simple, past_simple_examples, past_participle, past_participle_examples, text, links } }
            },
        },
    },
}
)

export const { addItem } = vocabularySlice.actions

// Other code such as selectors can use the imported `RootState` type
export const selectCount = (state: RootState) => state.vocabulary

export default vocabularySlice.reducer
