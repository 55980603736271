import * as React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { DataGrid, GridRowsProp, GridColDef } from '@mui/x-data-grid';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';

import { useAppSelector, useAppDispatch } from './app/hooks';
import { Item } from './app/features/vocabulary/vocabularySlice';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { Divider } from '@mui/material';

const columns: GridColDef[] = [
  { field: 'id', headerName: 'Id', width: 20 },
  { field: 'verb', headerName: 'Verb', width: 150 },
  { field: 'past_simple', headerName: 'Past Simple', width: 150 },
  { field: 'past_participle', headerName: 'Past Participle', width: 150 },
];


export default function Bulat() {
  // The `state` arg is correctly typed as `RootState` already
  const items = useAppSelector(state => state.vocabulary)
  const dispatch = useAppDispatch();

  const [selected_item, set_selected_item] = React.useState<Item | null>(null);
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleClickOpen = (data: any) => {
    console.log(data)
    set_selected_item(data.row)
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  //  const rows: GridRowsProp = items.map((i) => { id: i.id, col1: i.word, col2: i.text });
  const rows: GridRowsProp = items.map<Item>((i) => i);


  return (
    <Container maxWidth="sm">
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" component="h1" gutterBottom>
          Irregular Verbs
        </Typography>
        <Link href="https://www.cambridge.es/content/download/5266/38857/Grammar--Vocabulary-First-and-First-for-Schools-irregular-verbs.pdf" target="_blank">PDF</Link>
      </Box>
      <DataGrid rows={rows} columns={columns} onRowClick={handleClickOpen} />

      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        {/* <DialogTitle id="responsive-dialog-title">
          {selected_item?.verb}
        </DialogTitle> */}
        <Divider>
          <Typography variant="h4" color={'green'}>{selected_item?.verb}</Typography>
        </Divider>
        <DialogContent>
          <DialogContentText fontSize={30} color={'green'}>
            {selected_item?.verb_examples.map(e => <div>{e}</div>)}
          </DialogContentText>
        </DialogContent>

        <Divider>
          <Typography variant="h4" color={'blue'}>{selected_item?.past_simple}</Typography>
        </Divider>        
        <DialogContent>
          <DialogContentText fontSize={30} color={'blue'}>
            {selected_item?.past_simple_examples.map(e => <div>{e}</div>)}
          </DialogContentText>
        </DialogContent>

        <Divider>
          <Typography variant="h4" color={'brown'}>{selected_item?.past_participle}</Typography>
        </Divider>
        <DialogContent>
          <DialogContentText fontSize={30} color={'brown'}>
            {selected_item?.past_participle_examples.map(e => <div>{e}</div>)}
          </DialogContentText>
        </DialogContent>

        <Divider />
        
        <Divider>
          <Typography variant="h4" color={'black'}>{selected_item?.text}</Typography>
        </Divider>

        <Divider>
          <Box sx={{ my: 4 }}>
              {selected_item?.links.map(l => <Link href={l} target="_blank">link</Link>)}
          </Box>
        </Divider>
        
        <DialogActions>
            <Button autoFocus onClick={handleClose}>
              Close
            </Button>
        </DialogActions>
      </Dialog>

    </Container>
  );
}
