import { useState } from "react";

const useScore = (): [
    hitsCount: number,
    missesCount: number,
    hit: () => void,
    miss: () => void,
] => {

    const [hitsCount, setHitsCount] = useState(0);
    const [missesCount, setMissesCount] = useState(0);

    const hit = () => {
        setHitsCount(hits => hits + 1);
    };

    const miss = () => {
        setMissesCount(misses => misses + 1);
    };

    return [hitsCount, missesCount, hit, miss]
}

export default useScore;