import React, { useEffect, useState, useRef } from 'react';
import { Stage, Layer, Rect, Circle } from 'react-konva';
import Button from '@mui/material/Button';


interface DataItem {
    rectXLeft: number;
    rectXRight: number;

    rectY: number;

    yamX: number;
    yamY: number;

    areaWidth: number;

    circleX: number;
}

const Canvas = (props: any) => {
    const layer_width_shift = 100;
    const areaWidth = window.innerWidth - layer_width_shift;
    const [start, setStart] = useState(false);
    const [run, setRun] = useState(false);
    const [rectWidth, setRectWidth] = useState(window.innerWidth * 0.2);
    const [rectX, setRectX] = useState(Math.floor(Math.random() * (areaWidth - rectWidth)));
    const [rectY, setRectY] = useState(0);

    const yamRadius = 50;
    const [yamCoord, setYamCoord] = useState({ x: 0, y: 0 });

    const [circleX, setCircleX] = useState(window.innerWidth * 0.5);

    const currentCoordinates = useRef<any>();

    const data = useRef<DataItem[]>([]);

    const rectHeight = 50;
    const circlRadius = 50;

    const time_interval = 250;

    let timer: any = null;
    let timerRun: any = null;

    useEffect(() => {
        if (start) {
            currentCoordinates.current = { rectXLeft: rectX, rectXRight: rectX + rectWidth, rectY: rectY, yamX: yamCoord.x, yamY: yamCoord.y, };
            timer = !timer && setInterval(() => {
                setRectY(prev => prev + rectHeight);
                setYamCoord(prev => {
                    if (prev.x === 0 && prev.y === 0) {
                        return prev;
                    }
                    return {
                        x: prev.x,
                        y: prev.y + yamRadius,
                    }
                })

                if (!run) {
                    data.current.push({
                        rectXLeft: rectX,
                        rectXRight: rectX + rectWidth,

                        rectY: rectY,

                        yamX: yamCoord.x,
                        yamY: yamCoord.y,

                        areaWidth: areaWidth,

                        circleX: circleX,

                    });
                }
            }, time_interval);

            if (rectY >= window.innerHeight - rectHeight) {
                const rand = (Math.floor(Math.random() * 2)) + 1;
                console.log(rand);
                if (rand === 2) {
                    setYamCoord({ x: Math.floor(Math.random() * (areaWidth - yamRadius)), y: yamRadius });
                }

                setRectY(0);
                setRectX(Math.floor(Math.random() * (areaWidth - rectWidth)));
            }
            if (yamCoord.y >= window.innerHeight - yamRadius) {
                setYamCoord({ x: 0, y: 0 });
            }
        }

        return () => clearInterval(timer);
    }, [rectY, start]);

    useEffect(() => {
        timerRun = null;
        if (run) {
            timerRun = !timerRun && setInterval(() => {
                const c = currentCoordinates.current;

                const token = localStorage.getItem("jwt");
                if (!token) {
                    throw new Error("Not authorized");
                }

                fetch(
                    `${process.env.REACT_APP_PYTHON_BACKEND}/pred?rectXLeft=${Math.round(c.rectXLeft)}&rectXRight=${Math.round(c.rectXRight)}&rectY=${Math.round(c.rectY)}&yamX=${Math.round(c.yamX)}&yamY=${Math.round(c.yamY)}&areaWidth=${areaWidth}`,
                    {headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + token,
                    }},)
                    .then((res) => res.json())
                    .then((data) => {
                        setCircleX(data)
                        console.log({ rectX, rectX2: rectX + rectWidth, rectY, data })
                    })
            }, time_interval);
        }
        return () => timerRun && clearInterval(timerRun);
    }, [run])

    const handleKeyDown = (e: any) => {
        if (e.key === 'ArrowRight') {
            setCircleX(prev => prev + circlRadius);
        } else if (e.key === 'ArrowLeft') {
            setCircleX(prev => prev - circlRadius);
        } else if (e.key === 'Enter') {
            setStart(!start);
            if (start) {
                const blob = new Blob([JSON.stringify(data.current, null, 2)], { type: 'application/json' });

                saveFile(blob);
            }
        }
        // console.log(e);
    };

    const handleMouseDown = (e: any) => {
        setCircleX(e.pageX);
        // console.log(e);
    };

    const saveFile = async (blob: any) => {
        const a = document.createElement('a');
        a.download = 'data.json';
        a.href = URL.createObjectURL(blob);
        a.addEventListener('click', (e) => {
            setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
        });
        a.click();
    };


    return (
        // Stage - is a div wrapper
        // Layer - is an actual 2d canvas element, so you can have several layers inside the stage
        // Rect and Circle are not DOM elements. They are 2d shapes on canvas
        <div tabIndex={1} onKeyDown={handleKeyDown} onMouseDown={handleMouseDown}>
            <Button variant="outlined" onClick={() => { setRun(true); setStart(true); }}>Run</Button>
            <Stage width={areaWidth} height={window.innerHeight - 55} down>
                <Layer>
                    <Rect
                        x={rectX}
                        y={rectY}
                        width={rectWidth}
                        height={rectHeight}
                        fill="green"
                        shadowBlur={10}
                        cornerRadius={10}
                    />
                    <Circle
                        x={circleX}
                        y={window.innerHeight - 100}
                        stroke="yellow"
                        radius={circlRadius}
                        fill='red'
                        shadowBlur={10}
                        cornerRadius={10} />
                    {yamCoord.x !== 0 && yamCoord.y !== 0 && <Circle
                        x={yamCoord.x}
                        y={yamCoord.y}
                        radius={yamRadius}
                        fill='lightyellow'
                        shadowBlur={30}
                        cornerRadius={10} />
                    }
                </Layer>
            </Stage>
        </div>
    );
}

export default Canvas
